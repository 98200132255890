<template>
  <v-container fluid color="white">
    <center><h3>REGISTRO DE CLIENTE</h3></center>
    <v-form ref="validarRegistro">
      <div class="row">
        <div class="col-6">
          <v-text-field
            label="Documento o teléfono"
            required
            :rules="[(v) => !!v || 'El número de documento es requerido']"
            @focusout="validarDNI()"
            v-model="dataFrom.document"
            hide-details="auto"
            :error-messages="error"
            persistent-hint
            name="doc"
            id="doc"
          ></v-text-field>
        </div>
        <div class="col-6">
          <v-text-field
            label="Nombre Completo"
            required
            :rules="[(v) => !!v || 'El nombre completo es requerido']"
            v-model="dataFrom.tradename"
          ></v-text-field>
        </div>

        <div class="col-6">
          <v-text-field
            label="Teléfono"
            required
            :rules="[(v) => !!v || 'El Teléfono completo es requerido']"
            v-model="dataFrom.phone"
          ></v-text-field>
        </div>

        <div class="col-6">
          <v-text-field
            v-model="dataFrom.business_name"
            label="Nombre Negocio"
            required
            :rules="[(v) => !!v || 'El nombre negocio es requerido']"
          ></v-text-field>
        </div>
        <div class="col-6">
          <v-text-field
            label="Dirección"
            required
            v-model="dataFrom.address"
          ></v-text-field>
        </div>
      </div>

      <!--  -->

      <v-flex text-right>
        <v-btn class="success mx-1" small scrollable outlined @click="save">
          Guardar
        </v-btn>
      </v-flex>
    </v-form>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions, mapMutations, mapState } from "vuex";
import funcion from "@/mixins/funciones";
export default {
  mixins: [funcion],
  name: "registrarClienteComponet",
  data() {
    return {
      dataFrom: {
        tradename: "",
        business_name: "",
        document: "",
        address: "",
        status: 1,
        id_pais: 139,
        id_document: 2,
        id_branch: JSON.parse(localStorage.getItem("branch")),
        phone: "",
        accounts: [],
      },
      menu: false,
      modal: false,
      menu2: false,
      error: "",
    };
  },
  computed: {
    ...mapState(["datosPrincipales"]),
  },
  methods: {
    ...mapMutations(["setNuevoUsuarioFlag", "setDatosClientes"]),
    ...mapActions(["validarCliente"]),
    async save() {
      this.error = "";
      if (this.$refs.validarRegistro.validate()) {
        let valores = await this.obtenerDataApi(
          "post",
          process.env.VUE_APP_URL_MAIN + "addEntitie",
          this.dataFrom
        );

        if (valores.code == "ER_DUP_ENTRY") {
          this.error = "El número de documento ya se encuentra registrado";
          document.getElementById("doc").focus();
        } else {
          console.table(valores);
          this.datosPrincipales.idCliente = valores.insertId;
          this.datosPrincipales.name = valores.nameLong;
          this.datosPrincipales.phone = valores.phone;
          this.setNuevoUsuarioFlag();
          Swal.fire("CORRECTO", "Registro Correcto", "success");
          this.obtenerDatosCliente();
          this.$refs.validarRegistro.reset();
        }
      }
    },
    async obtenerDatosCliente() {
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getEntitiesList",
        {
          id_role: 18,
          id_branch: JSON.parse(localStorage.getItem("branch")),
        }
      );
      let clientes = [];
      valores.forEach((element) => {
        clientes.push({
          id: element.id,
          namelong: element.namelong,
          documentlong: element.documentlong,
          phone: element.phone,
        });
      });
      this.setDatosClientes(clientes);
    },
    async validarDNI() {
      
      let response = await this.validarCliente(this.dataFrom.document);
      if (response.statusBol == true) {
        document.getElementById("doc").focus();
        this.error = response.msg;
      } else {
        this.error = "";
      }
    },
  },
};
</script>

<style></style>
